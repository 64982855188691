.skill_section {
    text-align: center;
    font-weight: bold;

}

.progress {
    height: 50px;
    display: block;
    background: none;
}

.skill {
    padding: 10px 0;
    margin: 0 0 6px 0;
    text-transform: uppercase;
    display: block;
    font-weight: 800;
    font-family: "Poppins", sans-serif;
    color: #fff;
    font-size: 14px;
}

 .val {
    float: right;
    font-style: normal;
}

.progress-bar-wrap {
    background: rgba(255, 255, 255, 0.15);
}

.progress-bar {
    height: 25px;
    background-color: #f81f4e;
}