.nav {
    padding-top: 10px;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

.logo {
    font-weight: bold;
}

.mobile-menu-item {
    display: none;
}

ul {
    display: flex;
    flex-direction: row;
    list-style: none;
}

ul li a {
    text-decoration: none;
    margin-right: 2em;
    color: white;
    font-weight: bold;
    cursor: pointer;
}

ul li a:hover {
    color: #f81f4e !important;
}

#hero {
    margin-top: 5em;
}

.row {
    justify-content: space-around !important;
}

.name {
    font-size: 70px;
    margin-bottom: 10px;
    font-weight: bold;
}

.position {
    font-weight: 30px;
}

.mydescription {
    font-size: 23px;
}

.contactmebtn {
    width: 200px;
    height: 50px;
    border-radius: 15px;
    border: 2px solid #f81f4e;
    font-size: 20px;
    background-color: black;
}

.contactmebtn:hover {
    background-color: #f81f4e;
    color: white;
}

.hero-right {}

.myimage {
    width: 500px;
    height: 500px;
}

.diff-color {
    color: #f81f4e;
}

.social-media-links {
    display: block;
    font-size: 26px;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    margin-top: 5.5em;
    padding-right: 1em;
}

.social-media-links li {
    margin-top: 12px;
}




@media screen and (max-width: 780px) {
    .nav-links {
        display: none;
    }

    .nav-mobile-links {
        position: absolute;
        display: block;
        list-style: none;
        left: 0;
        top: 80px;
        width: 100%;
        transition: all 0.2s ease-out;
        font-size: 20px;
        justify-content: center;
        align-items: center;
        background-color: black;
    }

    ul li {
        text-align: center;
        margin-top: 1em;
        margin-bottom: 1em;
        font-size: 25px;
    }

    .nav-itemss {
        text-align: center;
        margin-top: 4em;
        margin-bottom: 4em;
    }

    .mobile-menu-item {
        display: block;
        position: absolute;
        font-size: 25px;
        top: 15px;
        right: 25px;
        border: none;
        outline: none;
        background-color: black;
    }

    .logo {
        margin-top: .4em;
    }

    #hero {
        margin-top: 3em;
    }

    .name {
        font-size: 40px;
        margin-bottom: 10px;
        font-weight: bold;
    }

    .myimage {
        width: 430px;
        left: 0;
        margin-left: 0;
        height: 500px;
        margin-top: 2em;
    }

    .social-media-links {
        display: flex;
        align-items: center;
        margin-left: 2em;
        margin-top: .5em;
    }
}