.btn_download{
    border: 2px solid #f81f4e !important;
    width: 230px;
    height: 50px;
    border-radius: 15px;
    font-size: 20px;
    background-color: black;
    padding: .5em;
    margin-left: 1em;
    color: white;
    white-space: nowrap;
    justify-content: center;
}

.btn_download:hover {
    background-color: #f81f4e;
}

@media screen and (max-width: 780px) {
    .container {
        display: block;
    }

    .btn_download {
        margin-left: 0 !important;
        margin-top: .5em;
    }
}

